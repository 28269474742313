import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FabricjsEditorComponent } from 'projects/angular-editor-fabric-js/src/public-api';
import { PreviewService } from 'src/app/services/preview.service';

@Component({
  selector: 'app-canvas-preview',
  templateUrl: './canvas-preview.component.html',
  styleUrls: ['./canvas-preview.component.scss']
})
export class CanvasPreviewComponent implements OnInit {

  @Input() canvas : FabricjsEditorComponent;
  jsonObject : Object;
  previewURL = '';

  constructor(private previewService: PreviewService) { }

  ngOnInit(): void {
  }

  public updateCanvasJSON() {
    this.canvas.rasterizeJSON();
    this.jsonObject = JSON.parse(this.canvas.json);
  }

  public openPreviewWindow() {
    let mockID;
    this.updateCanvasJSON();
    console.log(this.jsonObject);
    this.previewService.createMockActivity(this.jsonObject).subscribe(resp => {
      console.log(resp);
      mockID = resp['id'];
      this.previewURL= this.previewService.getPreviewURL(mockID);
      window.open(this.previewURL, '', 'width=400, height=800');
    });
  }
}
