import {Component, Input, OnInit} from '@angular/core';
import { ImagesService } from '../../services/images.service';
import { Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-image-list',
  templateUrl: './image-list.component.html',
  styleUrls: ['./image-list.component.scss']
})
export class ImageListComponent implements OnInit {
  images: any[];
  imagesFound = false;
  searching = false;
  searchQuery: any;
  @Output() imageClicked = new EventEmitter<string>();
  @Input() service: string;

  constructor(private imageService: ImagesService) {
    this.service = 'pixabay'
  }

  handleSuccess(data) {

    if (this.service == 'pixabay') {
      this.images = data.hits;
    } else {
      this.images = data.data;
    }

    this.imagesFound = true;
  }

  handleError(error) {
    console.log(error);
  }

  searchImages(query: string) {
    this.searching = true;
    if (this.service == 'pixabay') {
      return this.imageService.getImages(query).subscribe(
        data => this.handleSuccess(data),
        error => this.handleError(error),
        () => this.searching = false
      );
    } else {
      return this.imageService.getGifs(query).subscribe(
        data => this.handleSuccess(data),
        error => this.handleError(error),
        () => this.searching = false
      );
    }

  }

  ngOnInit() {
  }

  clickImage(img) {
    this.imageClicked.emit(img);
  }

}
