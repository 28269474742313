import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { FabricjsEditorModule } from '../../projects/angular-editor-fabric-js/src/public-api';
import { FormsModule } from '@angular/forms';
import { ColorPickerModule } from 'ngx-color-picker';

import { ImageListComponent } from './components/image-list/image-list.component';
import { IconListComponent } from './components/icon-list/icon-list.component';
import { ImagesService } from './services/images.service';
import { IconsService } from './services/icons.service';
import { HttpClientModule } from '@angular/common/http';
import { CanvasPreviewComponent } from './components/canvas-preview/canvas-preview.component';
import { EditorToolbarComponent } from './editor-toolbar/editor-toolbar.component';

@NgModule({
  declarations: [
    AppComponent,
    ImageListComponent,
    IconListComponent,
    CanvasPreviewComponent,
    EditorToolbarComponent
  ],
  imports: [
    BrowserModule,
    FabricjsEditorModule,
    FormsModule,
    ColorPickerModule,
    HttpClientModule
  ],
  providers: [ImagesService, IconsService],
  bootstrap: [AppComponent]
})
export class AppModule { }
