import { Component, OnInit } from '@angular/core';
import { IconsService } from '../../services/icons.service';
import { Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-icon-list',
  templateUrl: './icon-list.component.html',
  styleUrls: ['./icon-list.component.scss']
})
export class IconListComponent implements OnInit {
  icons: any[];
  iconsFound = false;
  searching = false;
  searchQuery: any;
  @Output() iconClicked = new EventEmitter<string>();

  constructor(private iconService: IconsService) {}

  handleSuccess(data) {
    this.icons = data.data;
    this.iconsFound = true;
  }

  handleError(error) {
    console.log(error);
  }

  searchIcons(query: string) {
    this.searching = true;
    return this.iconService.getIcons(query).subscribe(
      data => this.handleSuccess(data),
      error => this.handleError(error),
      () => this.searching = false
    );
  }

  ngOnInit() {
  }

  clickIcon(img) {
    this.iconClicked.emit(img);
  }

}
