import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders  } from '@angular/common/http';

@Injectable()
export class ImagesService {

    private query: string;
    private PIXABAY_API_KEY: string = environment.PIXABAY_API_KEY;
    private GIPHY_API_KEY: string = environment.GYPHY_API_KEY;
    private API_URL_PIXABAY: string = environment.PIXABAY_API_URL;
    private API_URL_GIPHY: string = environment.GYPHY_API_URL;
    private URL_PIXABAY: string = this.API_URL_PIXABAY + '?key=' + this.PIXABAY_API_KEY + '&q=';
    private URL_GIPHY: string = `${this.API_URL_GIPHY}?api_key=${this.GIPHY_API_KEY}&limit=10&offset=0&rating=G&lang=en&q=`;
    private perPage: string = '&per_page=10';
    private S3_API_URL: string = environment.S3_API_URL;

    constructor(private _http: HttpClient) { }

    getImages(query) {
      return this._http.get(this.URL_PIXABAY + query + this.perPage);
    }

    getGifs(query) {
      return this._http.get(this.URL_GIPHY + query + this.perPage);
    }

    uploadToS3(base64){
      return this._http.post(this.S3_API_URL, "file_data=" + encodeURIComponent(base64), {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded')
          .set('Accept-Encoding', 'gzip, deflate, br')
      }).toPromise();
    }


}
