import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient  } from '@angular/common/http';

@Injectable()
export class IconsService {

    private API_URL: string = environment.FLATICON_API_URL;
    private URL: string = this.API_URL + '?q=';

    constructor(private http: HttpClient) { }

    getIcons(query) {
      const headers = {
        Accept: '*/*'
      };
      return this.http.get(this.URL + query, { headers });
    }

}
