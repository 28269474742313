
<div style="display: none;">
    <div #LoadModal style="padding: 10px;">
        <input type="file" accept="application/JSON" (change)="readJSONFile($event);">
    </div>
</div>

<button data-toggle="tooltip" data-placement="bottom"
        title="Export to JSON File" type="button"
        class="btn btn-primary pl-1" style="margin-right: 10px;"
        (click)="downloadJson();">
    <i class="fa fa-download" aria-hidden="true"></i> Export to JSON File
</button>

<button data-toggle="tooltip" data-placement="bottom"
        title="Export to image" type="button"
        class="btn btn-primary pl-1" style="margin-right: 10px;"
        (click)="rasterize();">
    <i class="fa fa-download" aria-hidden="true"></i> Export to image
</button>

<button data-toggle="tooltip" data-placement="bottom"
        title="Export to SVG" type="button" class="btn btn-primary pl-1"
        style="margin-right: 10px;" (click)="rasterizeSVG();">
    <i class="fa fa-download" aria-hidden="true"></i> Export to SVG
</button>

<button data-toggle="tooltip" data-placement="bottom"
        title="Save in localStorage" type="button"
        class="btn btn-primary pl-1" style="margin-right: 10px;"
        (click)="saveCanvasToJSON();">
    <i class="fa fa-save" aria-hidden="true"></i> Save local
</button>

<button data-toggle="tooltip" data-placement="bottom"
        title="Load from localStorage" type="button"
        class="btn btn-primary pl-1" style="margin-right: 10px;"
        (click)="loadCanvasFromJSON();">
    <i class="fa fa-save" aria-hidden="true"></i> Load local
</button>

<button data-toggle="tooltip" data-placement="bottom"
        title="Import from JSON File" type="button"
        class="btn btn-primary pl-1" style="margin-right: 10px;"
        (click)="openJSONImportModal();">
    <i class="fa fa-upload"></i> Load from JSON
</button>

<button data-toggle="tooltip" data-placement="bottom"
        title="Clean Canvas" type="button"
        class="btn btn-danger" (click)="confirmClear();">
    <i class="fa fa-ban" aria-hidden="true"></i> Clean
</button>