import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FabricjsEditorComponent } from 'projects/angular-editor-fabric-js/src/public-api';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-editor-toolbar',
  templateUrl: './editor-toolbar.component.html',
  styleUrls: ['./editor-toolbar.component.scss']
})
export class EditorToolbarComponent implements OnInit {

  @ViewChild('LoadModal', {static: false}) loadModal: ElementRef;
  @Input() canvas: FabricjsEditorComponent;

  parsedFile: Object;

  constructor() { }

  ngOnInit(): void {
  }

  public rasterize() {
    this.canvas.rasterize();
  }

  public rasterizeSVG() {
    // this.canvas.rasterizeSVG();
    const element = document.createElement('a');
    element.setAttribute('href', 'data:image/svg+xml;utf8,' + encodeURIComponent(this.canvas.getSVG()));
    element.setAttribute('download', 'card.svg');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click(); // simulate click
    document.body.removeChild(element);
  }

  public saveCanvasToJSON() {
    this.canvas.saveCanvasToJSON();
  }

  public loadCanvasFromJSON() {
    this.canvas.loadCanvasFromJSON();
  }

  public confirmClear() {
    this.canvas.confirmClear();
  }

  public readJSONFile(input) {
    const file = input.target.files[0];
    const reader = new FileReader();

    console.log(file)

    reader.onloadend = (readerEvent) => {
      if (typeof reader.result === 'string') {
        this.parsedFile = JSON.parse(reader.result);
      }
    };
    reader.readAsText(file);
  }

  public openJSONImportModal() {
    Swal.fire({
      title: 'Load JSON File',
      html: this.loadModal.nativeElement,
      confirmButtonText: 'Load',
      showCancelButton: true
    }).then((result) => {
      if (result.isConfirmed){
        this.canvas.loadCanvasfromJSONFile(this.parsedFile);
      }
    })
  }

  public downloadJson() {
    this.canvas.rasterizeJSON();
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/json;charset=UTF-8,' + encodeURIComponent(this.canvas.json));
    element.setAttribute('download', 'primer-server-task.json');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click(); // simulate click
    document.body.removeChild(element);
  }

}
