<div class="container">
  <div class="row">
      <div class="col-xl-12">
          <div class="row">
              <div class="col-xl-12 text-right">
                  <br>
                  <app-editor-toolbar [canvas]="canvas"></app-editor-toolbar>
              </div>
          </div>
      </div>
  </div>
  <hr>
  <div class="row">
      <div class="col-xl-3">
          <div class="card">
              <div class="card-header">Add figure</div>
              <div class="card-body text-center max-height">
                  <div class="btn-group btn-group-vertical" role="group" aria-label="...">
                      <button type="button" class="btn btn-primary" (click)="addInput();">Input</button>
                      <button type="button" class="btn btn-primary" (click)="addText();">Text</button>
                      <button type="button" class="btn btn-primary" (click)="addFigure('rectangle');">Rectangle</button>
                      <button type="button" class="btn btn-primary" (click)="addFigure('square');">Square</button>
                      <button type="button" class="btn btn-primary" (click)="addFigure('triangle');">Triangle</button>
                      <button type="button" class="btn btn-primary" (click)="addFigure('circle');">Circle</button>
                    <button type="button" class="btn btn-primary" (click)="addButton();">Button</button>
                  </div>
              </div>
          </div>
          <br/>
          <div class="card">
            <div class="card-header">Buscar Imágenes</div>
            <div class="card-body max-height">
                <app-image-list [service]="serviceImages"  (imageClicked)="clickImageComponentEvent($event)" ></app-image-list>
            </div>
          </div>
          <br/>
          <div class="card">
            <div class="card-header">Buscar Gifs</div>
            <div class="card-body max-height">
              <app-image-list [service]="serviceGifs"  (imageClicked)="clickGiftComponentEvent($event)" ></app-image-list>
            </div>
          </div>
          <br/>
          <div class="card">
            <div class="card-header">Buscar Íconos</div>
            <div class="card-body max-height">
              <app-icon-list  (iconClicked)="clickIconComponentEvent($event)" ></app-icon-list>
            </div>
          </div>
          <br/>
          <div class="card">
              <div class="card-header">Upload image</div>
              <div class="card-body text-center">
                  <img id="testImage" *ngIf="canvas.url && !checkVideoURL(canvas.url)" class="images-item-upload" [src]="canvas.url" (click)="uploadImageOrGifOnCanvas(canvas.url);">
                  <video autoplay="true" loop="true" controls="false" id="testVideo" *ngIf="canvas.url && checkVideoURL(canvas.url)" class="images-item-upload" [src]="canvas.url"></video>
                  <input type="file" (change)="readUrl($event);">
                  <br/>
                  <br/>
                  <div class="btn-group btn-group-justified" role="group" aria-label="...">
                      <div class="btn-group" role="group">
                        <button type="button" class="btn btn-outline-primary btn-sm" (click)="uploadImageOrGifOnCanvas(canvas.url);">
                          <i class="fa fa-times" aria-hidden="true"></i> Add</button>
                        <button type="button" class="btn btn-outline-danger btn-sm" (click)="removeWhite(canvas.url);">
                          <i class="fa fa-times" aria-hidden="true"></i> Remove</button>
                      </div>
                  </div>
              </div>
          </div>
          <br>

      </div>
    <div class="col-xl-6" style="display: flex;">
          <angular-editor-fabric-js #canvas></angular-editor-fabric-js>
      </div>
      <div class="col-xl-3">
          <div class="card">
              <div class="card-body">
                <app-canvas-preview [canvas]="canvas"></app-canvas-preview>
              </div>
          </div>
          <br>
          <div class="card">
              <div class="card-header">Options</div>
              <div class="card-body text-center">
                  <div class="btn-group" role="group" aria-label="...">
                      <button data-toggle="tooltip" data-placement="bottom" title="Toggle grid" type="button" class="btn btn-outline-primary"
                              (click)="toggleGrid();">
                        <i class="fa fa-th" aria-hidden="true"></i>
                      </button>
                      <button data-toggle="tooltip" data-placement="bottom" title="Delete element" type="button" class="btn btn-outline-danger" [disabled]="!canvas.selected"
                          (click)="removeSelected();">
                          <i class="fa fa-trash" aria-hidden="true"></i>
                      </button>
                      <button data-toggle="tooltip" data-placement="bottom" title="Send to back" type="button" class="btn btn-outline-primary" [disabled]="!canvas.selected"
                          (click)="sendToBack();">
                          <i class="fa fa-level-down" aria-hidden="true"></i>
                      </button>
                      <button data-toggle="tooltip" data-placement="bottom" title="Send to front" type="button" class="btn btn-outline-primary" [disabled]="!canvas.selected"
                          (click)="bringToFront();">
                          <i class="fa fa-level-up" aria-hidden="true"></i>
                      </button>
                      <button data-toggle="tooltip" data-placement="bottom" title="Clone" type="button" class="btn btn-outline-primary" [disabled]="!canvas.selected || canvas.selected.type == 'group'"
                          (click)="clone();">
                          <i class="fa fa-clone" aria-hidden="true"></i>
                      </button>
                      <button data-toggle="tooltip" data-placement="bottom" title="Unselect" type="button" class="btn btn-outline-primary" [disabled]="!canvas.selected"
                          (click)="cleanSelect()">
                          <i class="fa fa-remove" aria-hidden="true"></i>
                      </button>
                  </div>
              </div>
          </div>
          <br/>
          <div class="card" *ngIf="!canvas.selected">
              <div class="card-header">Canvas</div>
              <div class="card-body">
                  <div class="custom-item" *ngIf="!canvas.props.canvasImage">
                      <div class="custom-item-title">Background Color</div>
                      <div class="custom-item-body">
                          <input type="text" class="form-control" [cpPosition]="'bottom'" [(colorPicker)]="canvas.props.canvasFill" [style.background]="canvas.props.canvasFill"
                              [value]="canvas.props.canvasFill" (colorPickerChange)="setCanvasFill()">
                      </div>
                  </div>
                  <div class="custom-item">
                      <div class="custom-item-title">Background Image (url)</div>
                      <div class="custom-item-body">
                          <input type="text" class="form-control" placeholder="http://example.jpg" [(ngModel)]="canvas.props.canvasImage" (keyup)="setCanvasImage()">
                      </div>
                  </div>
              </div>
          </div>
          <br/>
          <div class="card">
              <div class="card-header">Custom</div>
              <div class="card-body">
                  <div class="custom-item" *ngIf="canvas.selected  && canvas.selected.type == 'group'">Group Selected</div>

                  <div class="custom-item" *ngIf="canvas.selected  && canvas.selected.type == 'group' && canvas.selected.input?.type !== 'button'">
                        <div class="custom-item-title">Input Name</div>
                        <div class="custom-item-body">
                            <input type="text" class="form-control" [(ngModel)]="canvas.props.inputName" (keyup)="setInputName()">
                        </div>
                  </div>

                  <div class="custom-item" *ngIf="!canvas.selected">No items selected</div>
                  <div class="custom-item" *ngIf="canvas.selected && canvas.selected.type != 'group'">
                      <div class="custom-item-title">ID</div>
                      <div class="custom-item-body">
                          <input type="text" class="form-control" [(ngModel)]="canvas.props.id" (keyup)="setId()">
                      </div>
                  </div>
                  <div class="custom-item" *ngIf="canvas.selected && canvas.selected.type != 'group'">
                      <div class="custom-item-title">Opacity</div>
                      <div class="custom-item-body">
                          <input type="range" [(ngModel)]="canvas.props.opacity" (change)="setOpacity()">{{canvas.props.opacity}}</div>
                  </div>
                  <div class="custom-item" *ngIf="canvas.selected && canvas.selected.type === 'rect'">
                    <div class="custom-item-title">Border Radius</div>
                    <div class="custom-item-body">
                        <label for="rx">Horizontal</label>
                        <input type="range" name="rx" [(ngModel)]="canvas.props.rx" (change)="setBorderRadius()">{{canvas.props.rx}}

                        <label for="ry">Vertical</label>
                        <input type="range" name="ry" min="0" max="100" [(ngModel)]="canvas.props.ry" (change)="setBorderRadius()">{{canvas.props.ry}}
                    </div>
                  </div>
                  <div class="custom-item" *ngIf="canvas.selected && canvas.textEditor || canvas.selected && canvas.figureEditor">
                      <div class="custom-item-title">Fill</div>
                      <div class="custom-item-body">
                          <input type="text" class="form-control" [cpPosition]="'bottom'" [(colorPicker)]="canvas.props.fill" [style.background]="canvas.props.fill"
                              [value]="canvas.props.fill" (colorPickerChange)="setFill()">
                      </div>
                  </div>
                  <div class="custom-item" *ngIf="canvas.selected && canvas.textEditor && canvas.selected.type == 'group' && canvas.selected.input">
                    <div class="custom-item-title">Placeholder/Label</div>
                    <div class="custom-item-body">
                        <input type="text" class="form-control" [(ngModel)]="canvas.props.text" (keyup)="setText()">
                    </div>
                  </div>
                  <div class="custom-item" *ngIf="canvas.selected && canvas.textEditor">
                      <div class="custom-item-title">Font family</div>
                      <div class="custom-item-body d-flex">
                          <select [(ngModel)]="canvas.props.fontFamily" class="form-control" (change)="setFontFamily()">
                            <option value="arial">Arial</option>
                            <option value="helvetica" selected>Helvetica</option>
                            <option value="verdana">Verdana</option>
                            <option value="courier">Courier</option>
                            <!-- <option value="Roboto">Roboto</option> -->
                            <!-- <option value="Open Sans">Open Sans</option> -->
                            <!-- <option value="Zilla Slab">Zilla Slab</option> -->
                            <!-- <option value="Lato">Lato</option> -->
                            <!-- <option value="Bellefair">Bellefair</option> -->
                            <!-- <option value="Fresca">Fresca</option> -->
                            <!-- <option value="Raleway">Raleway</option> -->
                            <!-- <option value="Open Sans Condensed">Open Sans Condensed</option> -->
                            <!-- <option value="Indie Flower">Indie Flower</option> -->
                            <!-- <option value="Josefin Sans">Josefin Sans</option> -->
                            <!-- <option value="Inconsolata">Inconsolata</option> -->
                            <!-- <option value="Pacifico">Pacifico</option> -->
                            <!-- <option value="Gloria Hallelujah">Gloria Hallelujah</option> -->
                            <option value="Futura Std">Futura Std Bold</option>
                            <!-- <option value="Montserrat">Montserrat</option> -->
                            <!-- <option value="Calibri">Calibri</option> -->
                            <option *ngFor="let font of customFonts" [value]="font.name">{{font.name}}</option>
                          </select>
                          <button type="button" class="btn btn-primary ml-2" (click)="addCustomFont = !addCustomFont">
                            <i class="fa fa-ellipsis-h"></i>
                          </button>
                      </div>
                      <div class="custom-item-body d-flex" *ngIf="addCustomFont">
                        <input type="text" class="form-control" [(ngModel)]="canvas.props.fontFamily" (keyup)="setFontFamily()">
                        <button type="button" class="btn btn-primary ml-2" (click)="loadFonts(canvas.props.fontFamily)">
                            <i class="fa fa-plus"></i>
                        </button>
                      </div>
                  </div>
                  <div class="custom-item" *ngIf="canvas.selected && canvas.textEditor">
                      <div class="custom-item-title">Text Align</div>
                      <div class="custom-item-body text-center">
                          <div class="btn-group" role="group" aria-label="...">
                              <button type="button" class="btn btn-primary" [ngClass]="{'active': canvas.props.textAlign == 'left' }" (click)="setTextAlign('left')">
                                  <i class="fa fa-align-left"></i>
                              </button>
                              <button type="button" class="btn btn-primary" [ngClass]="{'active': canvas.props.textAlign == 'center' }" (click)="setTextAlign('center')">
                                  <i class="fa fa-align-center"></i>
                              </button>
                              <button type="button" class="btn btn-primary" [ngClass]="{'active': canvas.props.textAlign == 'right' }" (click)="setTextAlign('right')">
                                  <i class="fa fa-align-right"></i>
                              </button>
                              <button type="button" class="btn btn-primary" [ngClass]="{'active': canvas.props.textAlign == 'justify' }" (click)="setTextAlign('justify')">
                                  <i class="fa fa-align-justify"></i>
                              </button>
                          </div>
                      </div>
                  </div>
                  <div class="custom-item" *ngIf="canvas.selected && canvas.textEditor">
                      <div class="custom-item-title">Style</div>
                      <div class="custom-item-body text-center">
                          <div class="btn-group" role="group" aria-label="...">
                              <button type="button" class="btn btn-primary btn-sm" [ngClass]="{'active': canvas.props.fontWeight }" (click)="setBold()">
                                  <i class="fa fa-bold"></i>
                              </button>
                              <button type="button" class="btn btn-primary btn-sm" [ngClass]="{'active': canvas.props.fontStyle }" (click)="setFontStyle()">
                                  <i class="fa fa-italic"></i>
                              </button>
                              <button type="button" class="btn btn-primary btn-sm" [ngClass]="{'active': hasTextDecoration('underline') }"
                                  (click)="setTextDecoration('underline')">
                                  <i class="fa fa-underline"></i>
                              </button>
                              <button type="button" class="btn btn-primary btn-sm" [ngClass]="{'active': hasTextDecoration('overline') }"
                                  (click)="setTextDecoration('overline')">
                                  <i class="fa fa-underline fa-flip-vertical"></i>
                              </button>
                              <button type="button" class="btn btn-primary btn-sm" [ngClass]="{'active': hasTextDecoration('line-through') }"
                                  (click)="setTextDecoration('line-through')">
                                  <i class="fa fa-strikethrough"></i>
                              </button>
                          </div>
                      </div>
                  </div>
                  <div class="custom-item" *ngIf="canvas.selected && canvas.textEditor">
                      <div class="custom-item-title">Font Size</div>
                      <div class="custom-item-body">
                          <input type="range" [(ngModel)]="canvas.props.fontSize" (change)="setFontSize()" step="1" min="1" max="120">{{canvas.props.fontSize}}</div>
                  </div>
                  <div class="custom-item" *ngIf="canvas.selected && canvas.textEditor">
                      <div class="custom-item-title">Line Height</div>
                      <div class="custom-item-body">
                          <input type="range" [(ngModel)]="canvas.props.lineHeight" (change)="setLineHeight()" step="0.1" min="0" max="10">{{canvas.props.lineHeight}}</div>
                  </div>
                  <div class="custom-item" *ngIf="canvas.selected && canvas.textEditor">
                      <div class="custom-item-title">Char Spacing</div>
                      <div class="custom-item-body">
                          <input type="range" [(ngModel)]="canvas.props.charSpacing" (change)="setCharSpacing()" step="10" min="-200" max="800">{{canvas.props.charSpacing}}</div>
                  </div>

                  <div class="custom-item" *ngIf="canvas.selected && canvas.selected.type === 'activeSelection'">
                    <div class="custom-item-title">Align</div>
                    <div class="custom-item-body text-center">
                        <div class="btn-group" role="group">
                            <button type="button" class="btn btn-primary"
                                    (click)="alignObjects('left')">
                                <i class="fa fa-align-left"></i>
                            </button>
                            <button type="button" class="btn btn-primary"
                                    (click)="alignObjects('center')">
                                <i class="fa fa-align-center"></i>
                            </button>
                            <button type="button" class="btn btn-primary"
                                    (click)="alignObjects('right')">
                                <i class="fa fa-align-right"></i>
                            </button>
                        </div>
                    </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <br>
</div>
