import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PreviewService {

  private PARTNER_KEY: string = environment.CLOSELLY_PARTNER_KEY;
  private API_URL: string = environment.CLOSELLY_API_BASE_URL + environment.CLOSELLY_API_ACTIVITY_MOCK;
  private PREVIEW_BASE_URL: string = environment.ACTIVITY_PREVIEW_URL + '/validate-and-redirect';

  constructor(private http: HttpClient) { }

  public createMockActivity(canvasJSON: Object) {
    let headers = new HttpHeaders({
      'token': this.PARTNER_KEY
    });

    let formData = new FormData();
    let activityJSON = this.wrap(canvasJSON);
    formData.append('data_json', activityJSON);
    console.log(activityJSON);
    console.log(formData);
    //return new Observable;
    return this.http.post(this.API_URL, formData, { headers: headers });
  }

  public getPreviewURL(id) {
    return `${this.PREVIEW_BASE_URL}/${environment.ACTIVITY_PREVIEW_KEY}?url=/canvas/json/${id}&disabled_pwa_install=true&mock=true`;
  }

  private wrap(canvasJson: Object) {
    let activity = {
      "id": 5051,
      "section_id": 1187,
      "course_id": 637,
      "title": "Activity Preview",
      "description": "Canvas activity",
      "level": 1,
      "content_id": 3,
      "content_type": "ContentCanvas",
      "created_at": "2022-02-09T10:09:23.998-03:00",
      "updated_at": "2022-02-09T13:24:36.850-03:00",
      "duration": "01:00",
      "points": 0,
      "debut_date": null,
      "approved": true,
      "entity_id": 637,
      "entity_type": "Course",
      "first_published": false,
      "assessment_type": null,
      "assessment_introduction": null,
      "enabled": true,
      "set_questions_type": "select",
      "challenge_instructions_url": null,
      "qualifiable": false,
      "autorrection": false,
      "autorrection_percent": null,
      "autorrection_comment": null,
      "course": {
        "id": 637,
        "name": "Stub",
        "description": "For activity preview.",
        "created_at": "2022-01-28T13:12:12.985-03:00",
        "updated_at": "2022-02-11T11:35:29.415-03:00",
        "business_id": 7,
        "available": false,
        "available_date": null,
        "active": null,
        "image_url": "https://s3-sa-east-1.amazonaws.com/closelly-prod/7/courses//main_images/1643386331_image.png",
        "position": 45,
        "published_catalog": false,
        "disable_date": null,
        "pre_requesite_id": null,
        "note": null,
        "instructor_id": null,
        "assigned_course": true,
        "about_course": null,
        "first_published": false,
        "outstanding": false,
        "complement_material": null,
        "update_status": "open",
        "campaign": false,
        "duration": -1,
        "certificate_delivery": false,
        "certificate_background_image_url": null,
        "all_enabled_activities": true,
        "positions_table_view": true,
        "certificate_logo_url": null,
        "certificate_sign_url": null,
        "certificate_name": null,
        "certificate_position": null,
        "certificate_company": null,
        "scheduled_date": null,
        "opened": true,
        "can_edit": true
      },
      "section": {
        "id": 1187,
        "name": "SECCION 1",
        "description": null,
        "created_at": "2022-01-28T13:28:53.341-03:00",
        "updated_at": "2022-01-28T13:28:53.341-03:00",
        "course_id": 637,
        "level": 1
      },
      "content": {
        "id": 3,
        "activity_id": 5051,
        "background_color": null,
        "canvas_cards": [
          {
            "id": 4,
            "content_canvas_id": 3,
            "body": canvasJson,
            "index": 1
          }
        ]
      },
      "type_content": "canvas",
      "status": "completed",
      "topics": [],
      "points_earned": 0,
      "completed_users": 2,
      "not_calification": 2,
      "calification_users": 0,
      "result_content": null
    }
    return JSON.stringify(activity);
  }
}
