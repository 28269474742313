// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  PIXABAY_API_KEY: '25148539-431bdde4e43a8a6e6ea09362e',
  PIXABAY_API_URL: 'https://pixabay.com/api/',
  GYPHY_API_URL: 'https://api.giphy.com/v1/gifs/search',
  GYPHY_API_KEY: 'UGHqDuuHVbK25SUuPNTTzXeC7gTiKbq3',
  FLATICON_API_URL: 'https://s3-closelly.herokuapp.com/flaticon',
  S3_API_URL: 'https://s3-closelly.herokuapp.com/to_s3',
  CLOSELLY_API_BASE_URL: 'https://api-testing.closelly.com/api',
  CLOSELLY_PARTNER_KEY: '9c72aff2d9aebad8648b13e20115074120a8bf7e',
  CLOSELLY_API_ACTIVITY_MOCK: '/internal/activity_mock_json',
  // ACTIVITY_PREVIEW_URL: 'http://localhost:8100',
  ACTIVITY_PREVIEW_URL: 'https://web-staging.web.app',
  ACTIVITY_PREVIEW_KEY: `bd7af99649eba08bd48674a8892d77dca176258f4e076c1c2515eac4d50b6c01SXpUSGJoL2xhV001ZDVBVEpGaG43dz09LS1YRGtBVWp4TkNrOS9EWmdVVmZxS2pRPT0%3D--561180c0bf58d4ec59c1b9cb1ca8762201485876`
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
