import { Component, OnInit, ViewChild } from '@angular/core';
import * as WebFont from 'webfontloader';
import { FabricjsEditorComponent } from '../../projects/angular-editor-fabric-js/src/public-api';
import { ImagesService } from '../app/services/images.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'angular-editor-fabric-js';
  serviceImages = 'pixabay';
  serviceGifs = 'giphy';
  imageName = '';

  // customFonts = [
  //               'Bellefair', 'Fresca',
  //               'Gloria Hallelujah', 'Inconsolata',
  //               'Indie Flower', 'Josefin Sans',
  //               'Lato', 'Open Sans',
  //               'Open Sans Condensed:300', 'Pacifico',
  //               'Raleway', 'Roboto',
  //               'Zilla Slab', 'Montserrat', 'Calibri',
  //               ];

  customFontName = '';
  customFonts = [
    {name: 'Bellefair', googleName: 'Bellefair'},
    {name: 'Fresca', googleName: 'Fresca'},
    {name: 'Gloria Hallelujah', googleName: 'Gloria Hallelujah'},
    {name: 'Inconsolata', googleName: 'Inconsolata'},
    {name: 'Indie Flower', googleName: 'Indie Flower'},
    {name: 'Josefin Sans', googleName: 'Josefin Sans'},
    {name: 'Lato', googleName: 'Lato'},
    {name: 'Open Sans', googleName: 'Open Sans'},
    {name: 'Open Sans Condensed', googleName: 'Open Sans Condensed:300'},
    {name: 'Pacifico', googleName: 'Pacifico'},
    {name: 'Raleway', googleName: 'Raleway'},
    {name: 'Roboto', googleName: 'Roboto'},
    {name: 'Zilla Slab', googleName: 'Zilla Slab'},
    {name: 'Montserrat', googleName: 'Montserrat'},
    {name: 'Calibri', googleName: 'Calibri'},
  ]

  addCustomFont = false;

  constructor(public imagesService: ImagesService) {
  }

  @ViewChild('canvas', {static: false}) canvas: FabricjsEditorComponent;


  ngOnInit(): void {
    this.loadFonts();
  }

  public loadFonts(extraFont?: string) {
    WebFont.load({
      google: {
        families: this.customFonts.map(font => font.googleName)
                  .concat(extraFont ? [extraFont] : [])
      },
      timeout: 5000
    });

    if (this.canvas) {
      this.canvas.update();
    }
  }

  public changeSize() {
    this.canvas.changeSize();
  }

  public addText() {
    this.canvas.textString = 'your text here';
    this.canvas.addIText();
  }

  public getImgPolaroid(event) {
    this.canvas.getImgPolaroid(event);
  }

  public checkGifURL(url) {
    return(url.match(/\.(gif)$/) != null);
  }

  public checkVideoURL(url) {
    return(url.match(/mp4;base64/g) != null);
  }

  async uploadToS3(url){

    return ;
  }

  async addImageOnCanvas(url) {
    if (this.checkGifURL(this.canvas.originName)) {
      this.canvas.addGiftOnCanvas(url);
    } else {
      this.canvas.addImageOnCanvas(url);
    }
  }

  async uploadImageOrGifOnCanvas(url) {
    const result = await this.imagesService.uploadToS3(url)
    if (this.checkGifURL(this.canvas.originName)) {
      this.canvas.addGiftOnCanvas(result["url"]);
    } else {
      this.canvas.addImageOnCanvas(result["url"]);
    }
  }


  public readUrl(event) {
    this.canvas.readUrl(event);
  }

  public removeWhite(url) {
    this.canvas.removeWhite(url);
  }

  public addFigure(figure) {
    this.canvas.addFigure(figure);
  }

  public removeSelected() {
    this.canvas.removeSelected();
  }

  public sendToBack() {
    this.canvas.sendToBack();
  }

  public bringToFront() {
    this.canvas.bringToFront();
  }

  public clone() {
    this.canvas.clone();
  }

  public cleanSelect() {
    this.canvas.cleanSelect();
  }

  public setCanvasFill() {
    this.canvas.setCanvasFill();
  }

  public setCanvasImage() {
    this.canvas.setCanvasImage();
  }

  public setId() {
    this.canvas.setId();
  }

  public setOpacity() {
    this.canvas.setOpacity();
  }

  public setFill() {
    this.canvas.setFill();
  }

  public setText() {
    this.canvas.setText();
  }

  public setInputName() {
    this.canvas.setInputName();
  }

  public setFontFamily() {
    this.canvas.setFontFamily();
  }

  public setBorderRadius() {
    this.canvas.setBorderRadius();
  }

  public setTextAlign(value) {
    this.canvas.setTextAlign(value);
  }

  public setBold() {
    this.canvas.setBold();
  }

  public setFontStyle() {
    this.canvas.setFontStyle();
  }

  public hasTextDecoration(value) {
    this.canvas.hasTextDecoration(value);
  }

  public setTextDecoration(value) {
    this.canvas.setTextDecoration(value);
  }

  public setFontSize() {
    this.canvas.setFontSize();
  }

  public setLineHeight() {
    this.canvas.setLineHeight();
  }

  public setCharSpacing() {
    this.canvas.setCharSpacing();
  }

  public rasterizeJSON() {
    this.canvas.rasterizeJSON();
  }

  public alignObjects(alignment: 'left' | 'center' | 'right') {
    this.canvas.setObjectAlignment(alignment);
  }

  public clickImageComponentEvent(img) {
      if (img && img.webformatURL) {
        this.canvas.addImageOnCanvas(img.webformatURL);
      }
  }

  public clickGiftComponentEvent(img) {
    if (img && img.images) {
      this.canvas.addGiftOnCanvas(img.images.original.url);
    }
  }


  public clickIconComponentEvent(img) {
    if (img && img.images) {
      this.canvas.addImageOnCanvas(img.images.png['512']);
    }
  }

  public addButton() {
    this.canvas.addButton();
  }

  public addInput() {
    this.canvas.addInput();
  }

  public addGift() {

  }

  public toggleGrid() {
    this.canvas.toggleGrid();
  }
}
